import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { baseWebApiURL } from '@env';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Subject } from 'rxjs';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class DmiService implements OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  constructor(public configService: ConfigService, private http: HttpClient) {}

  public upload(file: string | Blob | NzUploadFile, referenceDate: Date): Observable<any> {
    const formData = new FormData();

    const token = this.configService.getToken();

    if (!token) throw new Error('token inválido');

    formData.append('file', file as Blob);

    const month = referenceDate.getMonth() + 1;
    const year = referenceDate.getFullYear();

    return this.http.post(
      `${baseWebApiURL}/v1/Dmi?month=${month < 10 ? `0${month}` : month}&year=${year}`,
      formData
    );
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
