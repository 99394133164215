import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DownloadService } from '@core/services/download/download.service';
import { MessageService } from '@core/services/message/message.service';
import { userGroups } from '@env';
import { FinancialFileModel } from '@models/financial-file';
import { TranslateService } from '@ngx-translate/core';
import { ConsolidatedStatus, FinancialFileTypes } from '@shared/enums';
import { InconsistencyStatus } from '@shared/enums/inconsistency-status.enum';
import { CONSTANTS } from '@shared/utils/constants';
import { getContainerFinancialFileTypes } from '@shared/utils/utils';
import { capitalize, startCase } from 'lodash';
import { DateHelperService } from 'ng-zorro-antd/i18n';
import { Subject, takeUntil } from 'rxjs';

declare let require: any;
const FileSaver = require('file-saver');

@Component({
  selector: 'app-file-card',
  templateUrl: './file-card.component.html',
  styleUrls: ['./file-card.component.scss']
})
export class FileCardComponent implements OnInit, OnDestroy {
  @Input() userGroup = '';

  @Input() consolidatedFiles: FinancialFileModel[] = [];

  @Input() file!: FinancialFileModel;

  public FinancialFileTypes = FinancialFileTypes;

  public ConsolidatedStatus = ConsolidatedStatus;

  consolidatedFile?: FinancialFileModel;

  consolidatedStatus = 0;

  public squadName = '';

  public dateRef = new Date();

  public CONSTANTS = CONSTANTS;

  private readonly destroy$ = new Subject<void>();

  public userGroups = userGroups;

  step = 0;

  uploadIcon = '';

  correctionIcon = 'loading';

  approveIcon = '';

  consolidatedIcon = '';

  public upload_type_planned: string = CONSTANTS.UPLOAD_TYPE_PLANNED;

  public upload_type_executed: string = CONSTANTS.UPLOAD_TYPE_EXECUTED;

  public title = '';

  public InconsistencyStatus = InconsistencyStatus;

  public financialFileTypes = FinancialFileTypes;

  public validationFileId = '';

  public isVisibleModalApproveFile = false;

  isDownloading = false;

  displayDate: string | null = '';

  constructor(
    public translate: TranslateService,
    private message: MessageService,
    private downloadService: DownloadService,
    private router: Router,
    private datePipe: DateHelperService
  ) {}

  ngOnInit(): void {
    this.getCurrentStep();

    this.squadName = this.file.squad?.squadName ?? this.translate.instant('MediaHub');
    this.dateRef = new Date(this.file.referenceYear, this.file.referenceMonth - 1, 1);

    this.displayDate = this.datePipe.format(this.dateRef, 'MMMM, YYYY') ?? '';
  }

  getCurrentStep(): void {
    const consolidatedFile = this.consolidatedFiles.find(
      consolidated => consolidated.squadId == this.file.squadId
    );

    this.consolidatedFile = consolidatedFile;

    this.consolidatedStatus = consolidatedFile?.status ?? ConsolidatedStatus.None;

    if (
      this.consolidatedStatus == ConsolidatedStatus.Generated ||
      this.consolidatedStatus == ConsolidatedStatus.Approved ||
      this.consolidatedStatus == ConsolidatedStatus.Rejected
    ) {
      this.step = 3;
    } else if (
      this.file.validationFile &&
      ((this.file.status == ConsolidatedStatus.Error &&
        this.file.validationFile.status == InconsistencyStatus.PendingApproval) ||
        (this.file.status == ConsolidatedStatus.Validated &&
          this.file.validationFile.status == InconsistencyStatus.Approved))
    ) {
      this.step = 2;
    } else if (
      this.file.status == ConsolidatedStatus.Error &&
      this.file.validationFile &&
      this.file.validationFile.status == InconsistencyStatus.None
    ) {
      this.step = 1;
    } else {
      this.step = 0;
    }
  }

  public downloadFile(file: FinancialFileModel): void {
    this.isDownloading = true;
    const downloadFileName = this.getDownloadFileName(file);

    this.downloadService
      .downloadFile(this.file.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: blob => {
          FileSaver.saveAs(blob, downloadFileName);
          this.isDownloading = false;
        },
        error: error => {
          this.getMessageErrorDownloadFile(Number(error.status), file.fileName);
          this.isDownloading = false;
        }
      });
  }

  public getMessageErrorDownloadFile(error: number, fileName: string): void {
    const msg = this.getMessage(error, fileName);

    if (msg != '') this.message.showError(msg);
  }

  private getMessage(error: number, fileName: string): string {
    if (error === 404) {
      if (fileName === CONSTANTS.FILE_PLANNED) return 'File_not_found_planned';

      if (fileName === CONSTANTS.FILE_CONSOLIDATED) return 'File_not_found_consolidated';

      if (fileName === CONSTANTS.FILE_EXECUTED) return 'File_not_found_executed';
    }
    return `api_status.${error}`;
  }

  private getDownloadFileName = (file: FinancialFileModel): string => {
    const fileType = capitalize(getContainerFinancialFileTypes(file.fileType));

    const month = String(file.referenceMonth).padStart(2, '0');

    const year = file.referenceYear;

    const squadName = `${startCase(file?.squad?.squadName.toLowerCase()).replace(/ /g, '_')}`;

    let filename = `${fileType}_General_${month}_${year}.xlsx`;

    if (squadName) {
      filename = `${fileType}_${squadName}_${month}_${year}.xlsx`;
    }

    return filename;
  };

  public showApproveModal(): void {
    if (
      this.file.validationFile &&
      this.file.validationFile.status === InconsistencyStatus.PendingApproval
    ) {
      const { fileType } = this.file;

      let fileName = '';
      switch (fileType) {
        case FinancialFileTypes.Planned:
          fileName = CONSTANTS.FILE_PLANNED;
          break;
        case FinancialFileTypes.Executed:
          fileName = CONSTANTS.FILE_EXECUTED;
          break;
        case FinancialFileTypes.MediaHub:
          fileName = CONSTANTS.FILE_CONSOLIDATED;
          break;
      }

      this.title = `${fileName} ${CONSTANTS.MONTH_NAMES[this.file.referenceMonth - 1]} ${
        this.file.referenceYear
      }`;

      this.squadName = this.file.squad?.squadName || '';

      this.router.navigate([], {
        queryParams: {
          active_tab:
            this.file.fileType == FinancialFileTypes.MediaHub ? 2 : this.file.fileType - 1,
          open_approve: this.file.validationFile.id,
          ref_date: this.dateRef.toISOString().substring(0, 10)
        }
      });

      this.validationFileId = this.file.validationFile.id;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
