<nz-spin nzSize="large" [nzSpinning]="isSpinning">
  <div class="container-fluid">
    <div class="cm-page-title">
      {{ 'Settings' | translate }}
    </div>
    <nz-card>
      <form nz-form [formGroup]="validationForm" class="formConfigurationArea">
        <nz-row [nzGutter]="24">
          <nz-col class="mb-2" [nzXs]="{ span: 24 }">
            <nz-form-label class="p-0" nzRequired>{{
              'METABASE_EMBEDDING_SECRET_KEY' | translate
            }}</nz-form-label>
            <nz-form-item>
              <nz-form-control
                [nzLg]="{ span: 12 }"
                nzHasFeedback
                nzErrorTip="{{ 'Required_field' | translate }}"
              >
                <input nz-input formControlName="secretKey" required />
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col class="mb-2" [nzXs]="{ span: 24 }">
            <nz-form-label class="p-0" nzRequired>{{
              'METABASE_SITE_URL' | translate
            }}</nz-form-label>
            <nz-form-item>
              <nz-form-control
                [nzLg]="{ span: 12 }"
                nzHasFeedback
                nzErrorTip="{{ 'Required_field' | translate }}"
              >
                <input nz-input formControlName="siteUrl" required />
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col class="mb-2" [nzXs]="{ span: 24 }">
            <nz-form-label class="p-0" nzRequired>{{
              'METABASE_DASHBOARD_ID' | translate
            }}</nz-form-label>
            <nz-form-item>
              <nz-form-control
                [nzLg]="{ span: 12 }"
                nzHasFeedback
                nzErrorTip="{{ 'Required_field' | translate }}"
              >
                <input nz-input formControlName="dashboardId" required />
              </nz-form-control>
            </nz-form-item>
          </nz-col>
        </nz-row>
        <div class="d-flex justify-content-end my-2">
          <app-button class="me-2" type="link" (click)="cleanForm()">
            {{ 'Clean_form' | translate }}
          </app-button>
          <app-button type="primary" [disabled]="!validationForm?.valid" (click)="save()">
            {{ 'Save' | translate }}
          </app-button>
        </div>
      </form>
    </nz-card>
  </div>
</nz-spin>
