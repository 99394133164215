import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApprovedRulesService } from './approvedRule/approvedRule.service';
import { CampaignService } from './campaign/campaign.service';
import { DmiService } from './dmi/dmi.service';
import { DownloadService } from './download/download.service';
import { FileTypeService } from './fileTypes/fileTypes.service';
import { FinancialFileService } from './financial-file/financial-file.service';
import { InconsistencyService } from './inconsistency/inconsistency.service';
import { InconsistencyGroupsService } from './inconsistencyGroups/inconsistencyGroups.service';
import { MediaService } from './media/media.service';
import { MessageService } from './message/message.service';
import { NetworkService } from './network/network.service';
import { ProductService } from './product/product.service';
import { SquadService } from './squad/squad.service';
import { VehicleService } from './vehicle/vehicle.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],

  providers: [
    MessageService,
    FinancialFileService,
    DownloadService,
    SquadService,
    ProductService,
    MediaService,
    NetworkService,
    DmiService,
    InconsistencyService,
    VehicleService,
    CampaignService,
    ApprovedRulesService,
    InconsistencyGroupsService,
    FileTypeService
  ]
})
export class ServicesModule {}
