import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommitmentService } from '@core/services/commitment/commitment.service';
import { MessageService } from '@core/services/message/message.service';
import { TranslateService } from '@ngx-translate/core';
import { CommitmentCompleteModel, CommitmentModel, NetworkCompleteModel } from '@shared/models';
import { Subject, takeUntil } from 'rxjs';
import { NetworkService } from '../../../../core/services/network/network.service';
import { CommitmentComponentService } from '../commitment-component.service';

@Component({
  selector: 'app-commitment-form',
  templateUrl: './commitment-form.component.html',
  styleUrls: ['./commitment-form.component.scss']
})
export class CommitmentFormComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  validationForm!: FormGroup;

  commitments: CommitmentCompleteModel[] = [];

  networks: NetworkCompleteModel[] = [];

  disabledSaveBtn = true;

  constructor(
    private formBuilder: FormBuilder,
    private networkService: NetworkService,
    private message: MessageService,
    private commitmentService: CommitmentService,
    private commitmentComponentService: CommitmentComponentService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.getData();
    this.updateNetworksSubscribe();
    this.validationForm = this.formBuilder.group({
      commitmentValue: ['', [Validators.required]],
      commitmentNetwork: [null, [Validators.required]]
    });
  }

  getData(): void {
    this.commitmentComponentService.isFormLoading.next(true);
    this.networkService
      .getNetworksWithoutCommitment()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: networks => {
          this.networks = networks;
          this.commitmentComponentService.isFormLoading.next(false);
        },
        error: error => {
          this.commitmentComponentService.isFormLoading.next(false);
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  updateNetworksSubscribe(): void {
    this.commitmentComponentService.updateNetworks
      .pipe(takeUntil(this.destroy$))
      .subscribe((submittedForm: boolean) => {
        if (submittedForm) this.getData();
      });
  }

  cleanForm(): void {
    this.validationForm.controls['commitmentValue'].setValue('');
    this.validationForm.controls['commitmentValue'].reset();
    this.validationForm.controls['commitmentNetwork'].setValue('');
    this.validationForm.controls['commitmentNetwork'].reset();
  }

  handleSave(): void {
    this.commitmentComponentService.isFormLoading.next(true);

    const commitmentValue = this.validationForm.get('commitmentValue')?.value;
    const commitmentNetworkId = this.validationForm.get('commitmentNetwork')?.value;

    const commitment: CommitmentModel = {
      value: commitmentValue,
      networkId: commitmentNetworkId
    };

    this.commitmentService
      .saveCommitment(commitment)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.commitmentComponentService.isFormLoading.next(false);

          this.validationForm.controls['commitmentValue'].reset();
          this.validationForm.controls['commitmentNetwork'].reset();

          this.commitmentComponentService.updateCommitmentsGrid.next(true);

          this.message.showSuccess(this.translate.instant('Registration_saved'));
        },
        error: error => {
          this.commitmentComponentService.isFormLoading.next(false);
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
