import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConsolidatedService } from '@core/services/consolidated/consolidated.service';
import { DownloadService } from '@core/services/download/download.service';
import { FinancialFileService } from '@core/services/financial-file/financial-file.service';
import { MessageService } from '@core/services/message/message.service';
import { userGroups } from '@env';
import { FinancialFileModel } from '@models/financial-file';
import { TranslateService } from '@ngx-translate/core';
import { FilesComponentService } from '@pages/files/files-component.service';
import { UserInfoService } from '@pages/user-info.service';
import { ConsolidatedStatus } from '@shared/enums/consolidated-status';
import { CONSTANTS } from '@shared/utils/constants';
import { getContainerFinancialFileTypes } from '@shared/utils/utils';
import { capitalize, startCase } from 'lodash';
import { DateHelperService } from 'ng-zorro-antd/i18n';
import { Subject, takeUntil } from 'rxjs';

declare let require: any;
const FileSaver = require('file-saver');

@Component({
  selector: 'app-consolidated-card',
  templateUrl: './consolidated-card.component.html',
  styleUrls: ['./consolidated-card.component.scss']
})
export class ConsolidatedCardComponent implements OnInit, OnDestroy {
  @Input() userGroup = '';

  @Input() file!: FinancialFileModel;

  @Input() lastDmiImportDate: Date | null = null;

  @Input() dateRef = new Date();

  private readonly destroy$ = new Subject<void>();

  public CONSTANTS = CONSTANTS;

  public ConsolidatedStatus = ConsolidatedStatus;

  public userGroups = userGroups;

  public displayDate: string | null = '';

  loading = false;

  statusApprovedChanged = false;

  statusGeneratedChanged = false;

  isDownloading = false;

  constructor(
    public translate: TranslateService,
    public financialFileService: FinancialFileService,
    public consolidatedService: ConsolidatedService,
    public downloadService: DownloadService,
    public userInfoService: UserInfoService,
    public message: MessageService,
    private router: Router,
    private datePipe: DateHelperService,
    private filesComponentService: FilesComponentService
  ) {}

  ngOnInit(): void {
    this.displayDate =
      this.datePipe.format(
        new Date(this.file.referenceYear, this.file.referenceMonth - 1, 1),
        'MMMM, YYYY'
      ) ?? '';
    this.filesComponentService.loading.pipe(takeUntil(this.destroy$)).subscribe({
      next: loading => {
        if (!loading) {
          this.loading = loading;
          if (this.statusApprovedChanged) {
            this.message.showSuccess(this.translate.instant('Status_successfully_changed'));
          } else if (this.statusGeneratedChanged) {
            this.message.showSuccess(this.translate.instant('Consolidated_successfully_generated'));
          }
        }
      }
    });
  }

  public generateConsolidateSquad(file: FinancialFileModel): void {
    this.loading = true;
    this.consolidatedService
      .generatedConsolidatedSquad(file.squadId, file.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.financialFileService.updateFilesSubject.next();
          this.statusGeneratedChanged = true;
        },
        error: error => {
          this.loading = false;
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  public downloadFile(file: FinancialFileModel): void {
    this.isDownloading = true;
    const downloadFileName = this.getDownloadFileName(file);

    this.downloadService
      .downloadFile(this.file.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: blob => {
          FileSaver.saveAs(blob, downloadFileName);
          this.isDownloading = false;
        },
        error: error => {
          this.isDownloading = false;
          this.getMessageErrorDownloadFile(Number(error.status), file.fileName);
        }
      });
  }

  public getMessageErrorDownloadFile(error: number, fileName: string): void {
    const msg = this.getMessage(error, fileName);

    if (msg != '') this.message.showError(msg);
  }

  private getMessage(error: number, fileName: string): string {
    if (error === 404) {
      if (fileName === CONSTANTS.FILE_PLANNED) return 'File_not_found_planned';

      if (fileName === CONSTANTS.FILE_CONSOLIDATED) return 'File_not_found_consolidated';

      if (fileName === CONSTANTS.FILE_EXECUTED) return 'File_not_found_executed';
    }
    return `api_status.${error}`;
  }

  private getDownloadFileName = (file: FinancialFileModel): string => {
    const fileType = capitalize(getContainerFinancialFileTypes(file.fileType));

    const month = String(file.referenceMonth).padStart(2, '0');

    const year = file.referenceYear;

    const squadName = startCase(file?.squad?.squadName.toLowerCase()).replace(/ /g, '_');

    let filename = `${fileType}_General_${month}_${year}.xlsx`;

    if (squadName) {
      filename = `${fileType}_${squadName}_${month}_${year}.xlsx`;
    }

    return filename;
  };

  public showComparativeSquad(file: FinancialFileModel): void {
    this.router.navigate(['/files/comparative'], {
      queryParams: {
        financial_file_id: file.id,
        last_dmi_import_date: this.lastDmiImportDate,
        ref_date: this.dateRef.toISOString().substring(0, 10)
      }
    });
  }

  ngChangeStatus(status: ConsolidatedStatus): void {
    this.loading = true;

    this.consolidatedService
      .changeConsolidateSquadStatus(this.file.id, status)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.financialFileService.updateFilesSubject.next();
          this.statusApprovedChanged = true;
        },
        error: error => {
          this.loading = false;
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
