import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { CommitmentService } from '../../../../core/services/commitment/commitment.service';
import { MessageService } from '../../../../core/services/message/message.service';
import { NetworkService } from '../../../../core/services/network/network.service';
import {
  CommitmentCompleteModel,
  CommitmentModel,
  NetworkCompleteModel
} from '../../../../shared/models';
import { CommitmentComponentService } from '../commitment-component.service';

@Component({
  selector: 'app-commitment-edit',
  templateUrl: './commitment-edit.component.html',
  styleUrls: ['./commitment-edit.component.scss']
})
export class CommitmentEditComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isVisible = false;

  @Input() commitment: CommitmentCompleteModel = {} as CommitmentCompleteModel;

  @Output() isVisibleModalChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private readonly destroy$ = new Subject<void>();

  validationForm!: FormGroup;

  commitments: CommitmentCompleteModel[] = [];

  networks: NetworkCompleteModel[] = [];

  public isSpinning = false;

  public isLoadingNetworks = false;

  constructor(
    private formBuilder: FormBuilder,
    private networkService: NetworkService,
    private message: MessageService,
    private commitmentService: CommitmentService,
    private commitmentComponentService: CommitmentComponentService,
    public translate: TranslateService
  ) {}

  public handleClose(): void {
    this.isVisible = false;
    this.isSpinning = false;
    this.isVisibleModalChange.emit(this.isVisible);
  }

  ngOnInit(): void {
    this.validationForm = this.formBuilder.group({
      commitmentValue: [null, [Validators.required]],
      commitmentNetwork: [null, [Validators.required]]
    });
  }

  getData(): void {
    this.isLoadingNetworks = true;
    this.networkService
      .getNetworksWithoutCommitment(this.commitment.network.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: networks => {
          this.isLoadingNetworks = false;
          this.networks = networks;
        },
        error: error => {
          this.isLoadingNetworks = false;
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  handleSave(): void {
    this.isSpinning = true;

    const commitmentValue = this.validationForm.get('commitmentValue')?.value;
    const commitmentNetworkId = this.validationForm.get('commitmentNetwork')?.value;

    const commitment: CommitmentModel = {
      value: commitmentValue,
      networkId: commitmentNetworkId
    };

    this.commitmentService
      .updateCommitment(this.commitment.id, commitment)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.isSpinning = false;
          this.handleClose();

          this.commitmentComponentService.updateCommitmentsGrid.next(true);

          this.message.showSuccess(this.translate.instant('Registration_saved'));

          this.validationForm.controls['commitmentValue'].reset();
          this.validationForm.controls['commitmentNetwork'].reset();
        },
        error: error => {
          this.isSpinning = false;
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  afterOpen(): void {
    this.getData();
  }

  ngOnChanges(): void {
    if (this.commitment && this.validationForm) {
      this.validationForm.controls['commitmentValue'].setValue(this.commitment.value);
      this.validationForm.controls['commitmentNetwork'].setValue(this.commitment.network.id);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
