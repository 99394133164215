<div class="container-fluid">
  <nz-spin nzSize="large" [nzSpinning]="isSpinning">
    <div nz-col class="cm-page-title">
      {{ 'Dashboard' | translate }}
    </div>
    <div *ngIf="iframeUrl" class="iframe-container">
      <iframe [src]="iframeUrl" allowtransparency allowfullscreen></iframe>
    </div>
  </nz-spin>
</div>
