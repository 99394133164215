import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from '@shared/shared.module';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { AppNgZorroAntdModule } from '../app-ng-zorro-antd.module';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { MessageDmiErrorComponent } from '../pages/upload/message-dmi-error/message-dmi-error.component';
import { MessageFileErrorComponent } from '../pages/upload/message-file-error/message-file-error.component';
import { AlertComponent } from './alert/alert.component';
import { ButtonComponent } from './button/button.component';
import { ConsolidatedCardComponent } from './consolidated-card/consolidated-card.component';
import { CampaignDataViewComponent } from './data-view/campaign-data-view/campaign-data-view.component';
import { MediaDataViewComponent } from './data-view/media-data-view/media-data-view.component';
import { NetworkDataViewComponent } from './data-view/network-data-view/network-data-view.component';
import { PackageDataViewComponent } from './data-view/package-data-view/package-data-view.component';
import { ProductDataViewComponent } from './data-view/product-data-view/product-data-view.component';
import { PurchaseTypeDataViewComponent } from './data-view/purchase-type-data-view/purchase-type-data-view.component';
import { VehicleDataViewComponent } from './data-view/vehicle-data-view/vehicle-data-view.component';
import { FileCardComponent } from './file-card/file-card.component';
import { HeaderCalendarComponent } from './header-calendar/header-calendar.component';
import { ModalApproveFileComponent } from './modal-approve-file/modal-approve-file.component';
import { ModalUploadFileComponent } from './modal-upload-file/modal-upload-file.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ProfileSettingsComponent } from './profile-settings/profile-settings.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { ServicesModule } from '@core/services/services.module';
import { NzResultModule } from 'ng-zorro-antd/result';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NzGridModule } from 'ng-zorro-antd/grid';

function TranslateHttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AlertComponent,
    ButtonComponent,
    CampaignDataViewComponent,
    ConsolidatedCardComponent,
    ConsolidatedCardComponent,
    FileCardComponent,
    FileCardComponent,
    HeaderCalendarComponent,
    MediaDataViewComponent,
    MessageDmiErrorComponent,
    MessageFileErrorComponent,
    ModalApproveFileComponent,
    ModalUploadFileComponent,
    NetworkDataViewComponent,
    PackageDataViewComponent,
    PaginationComponent,
    ProductDataViewComponent,
    ProfileSettingsComponent,
    PurchaseTypeDataViewComponent,
    UserInfoComponent,
    VehicleDataViewComponent
  ],
  exports: [
    AlertComponent,
    ButtonComponent,
    CampaignDataViewComponent,
    ConsolidatedCardComponent,
    ConsolidatedCardComponent,
    FileCardComponent,
    FileCardComponent,
    HeaderCalendarComponent,
    MediaDataViewComponent,
    MessageDmiErrorComponent,
    MessageFileErrorComponent,
    ModalApproveFileComponent,
    ModalUploadFileComponent,
    NetworkDataViewComponent,
    PackageDataViewComponent,
    PaginationComponent,
    ProductDataViewComponent,
    ProfileSettingsComponent,
    PurchaseTypeDataViewComponent,
    UserInfoComponent,
    VehicleDataViewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NzAvatarModule,
    NzButtonModule,
    NzCardModule,
    NzCheckboxModule,
    NzDatePickerModule,
    NzDropDownModule,
    NzDrawerModule,
    NzFormModule,
    NzIconModule,
    NzInputModule,
    NzModalModule,
    NzPaginationModule,
    NzRadioModule,
    NzSelectModule,
    NzSpinModule,
    NzStepsModule,
    NzTableModule,
    NzToolTipModule,
    NzUploadModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    AppNgZorroAntdModule,
    NzResultModule,
    ServicesModule,
    BrowserModule,
    BrowserAnimationsModule,
    NzGridModule,
    TranslateModule.forRoot({
      defaultLanguage: 'pt',
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ]
})
export class ComponentsModule {}
