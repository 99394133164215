import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '@components/components.module';
import { ModalNewFromToComponent } from '@components/modal-new-from-to/modal-new-from-to.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { AppRoutingModule } from '../app-routing.module';
import { SpreadsheetModule } from '../components/spreadsheet/spreadsheet.module';
import { PipesModule } from '../shared/pipes/pipes.module';
import { ComparativeComponent } from './comparative/comparative.component';
import { ConfigurationAreaComponent } from './configuration-area/configuration-area.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FilesModule } from './files/files.module';
import { HomeComponent } from './home/home.component';
import { InconsistenciesModule } from './inconsistencies/inconsistencies.module';
import { RegistrationsComponent } from './registrations/registrations.component';
import { RegistrationsModule } from './registrations/registrations.module';
import { ReportComponent } from './report/report.component';
import { RulesGridComponent } from './rules/rules-grid/rules-grid.component';
import { RulesComponent } from './rules/rules.component';
import { UpdateColumnModule } from './update-column/update-column.module';
import { UserInfoService } from './user-info.service';
import { UsersModule } from './users/users.module';

function TranslateHttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    ComparativeComponent,
    HomeComponent,
    RegistrationsComponent,
    ReportComponent,
    RulesComponent,
    RulesGridComponent,
    RegistrationsComponent,
    ConfigurationAreaComponent,
    DashboardComponent,
    ModalNewFromToComponent
  ],
  exports: [HomeComponent],
  providers: [UserInfoService],
  imports: [
    TranslateModule.forRoot({
      defaultLanguage: 'pt',
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    CommonModule,
    ComponentsModule,
    FormsModule,
    InconsistenciesModule,
    UpdateColumnModule,
    NzAlertModule,
    NzButtonModule,
    NzCardModule,
    NzCheckboxModule,
    NzDatePickerModule,
    NzEmptyModule,
    NzIconModule,
    NzLayoutModule,
    NzModalModule,
    NzGridModule,
    NzSelectModule,
    NzInputModule,
    NzFormModule,
    FormsModule,
    ReactiveFormsModule,
    NzSpinModule,
    NzTableModule,
    NzTypographyModule,
    NzUploadModule,
    RegistrationsModule,
    RouterModule,
    SpreadsheetModule,
    UsersModule,
    PipesModule,
    FilesModule,
    NzToolTipModule
  ]
})
export class PagesModule {}
