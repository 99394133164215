<nz-spin nzSize="large" [nzSpinning]="isSpinning && allFiles.length === 0">
  <div class="container-fluid">
    <div class="d-flex justify-content-between">
      <nz-date-picker
        class="date-picker-files col col-3 mb-3"
        nzMode="month"
        nzFormat="MMMM yyyy"
        nzAllowClear="false"
        [nzDisabledDate]="disabledDate"
        [(ngModel)]="dateRef"
        (ngModelChange)="onChangeDateReference($event)"
      ></nz-date-picker>
      <app-button
        *ngIf="
          userGroup === userGroups.admin ||
          userGroup === userGroups.analyst ||
          userGroup === userGroups.squad
        "
        (click)="showUploadModal()"
        type="primary"
      >
        <span class="material-icons-outlined md-24 me-2">file_upload</span>
        {{ 'Send_data' | translate }}
      </app-button>
    </div>
    <div>
      <nz-tabset (nzSelectChange)="changeTab($event.index)" [nzSelectedIndex]="activeTab">
        <nz-tab nz-tab-link [routerLink]="['.']" nzTitle="{{ 'Planned' | translate }}">
          <app-common-file-tab
            [filesList]="plannedFiles"
            [consolidatedFiles]="consolidatedFiles"
            [userGroup]="userGroup"
          ></app-common-file-tab>

          <nz-empty
            *ngIf="!isSpinning && plannedFiles.length === 0"
            nzNotFoundImage="simple"
            [nzNotFoundContent]="contentTpl"
          ></nz-empty>
        </nz-tab>
        <nz-tab nzTitle="{{ 'Executed' | translate }}">
          <app-common-file-tab
            [filesList]="executedFiles"
            [consolidatedFiles]="consolidatedFiles"
            [userGroup]="userGroup"
          ></app-common-file-tab>

          <nz-empty
            *ngIf="!isSpinning && executedFiles.length === 0"
            nzNotFoundImage="simple"
            [nzNotFoundContent]="contentTpl"
          ></nz-empty>
        </nz-tab>
        <nz-tab
          *ngIf="mediaHubFiles && mediaHubFiles.length > 0"
          nzTitle="{{ 'MediaHub' | translate }}"
        >
          <app-common-file-tab
            [filesList]="mediaHubFiles"
            [consolidatedFiles]="consolidatedFiles"
            [userGroup]="userGroup"
          ></app-common-file-tab>

          <nz-empty
            *ngIf="!isSpinning && mediaHubFiles.length === 0"
            nzNotFoundImage="simple"
            [nzNotFoundContent]="contentTpl"
          ></nz-empty>
        </nz-tab>

        <nz-tab nzTitle="{{ 'Consolidated' | translate }}">
          <app-consolidated-tab
            [filesList]="consolidatedFiles"
            [userGroup]="userGroup"
            [lastDmiImportDate]="lastDmiImportDate"
            [dateRef]="dateRef"
            [fileGeneralConsolidated]="fileGeneralConsolidated"
          ></app-consolidated-tab>

          <nz-empty
            *ngIf="!isSpinning && consolidatedFiles.length === 0"
            nzNotFoundImage="simple"
            [nzNotFoundContent]="contentTpl"
          ></nz-empty>
        </nz-tab>
      </nz-tabset>

      <ng-template #contentTpl>
        <p>
          {{ 'No_files_available' | translate }}
        </p>
      </ng-template>
    </div>
  </div>
</nz-spin>

<app-modal-upload-file
  *ngIf="userGroup !== userGroups.customer"
  [isVisible]="isVisibleModalUploadFile"
  (isVisibleModalChange)="isVisibleModalUploadFile = $event"
>
</app-modal-upload-file>
<app-modal-approve-file
  *ngIf="userGroup !== userGroups.customer"
  [isVisible]="isVisibleModalApproveFile"
  (isVisibleModalChange)="isVisibleModalApproveFile = $event"
  [title]="title"
  [squadName]="squadName"
  [validationFileId]="validationFileId"
>
</app-modal-approve-file>
