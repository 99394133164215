import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from '@core/services/message/message.service';
import { SystemSettingsService } from '@core/services/systemSettings/systemSettings.service';
import { SystemSettingModel } from '@models/systemSetting';
import { CONSTANTS } from '@shared/utils/constants';
import * as jose from 'jose';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  private METABASE_SITE_URL: string | undefined;

  private METABASE_SECRET_KEY: Uint8Array | undefined;

  private METABASE_DASHBOARD_ID: number | undefined;

  public isSpinning = false;

  public iframeUrl: SafeResourceUrl | undefined;

  systemSettings: SystemSettingModel[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public message: MessageService,
    public sanitizer: DomSanitizer,
    private systemSettingService: SystemSettingsService
  ) {}

  private showModalFromRoute(): void {
    const { message } = this.route.snapshot.data;

    if (message === 'not_found') {
      this.message.showErrorByStatus(404);
      this.router.navigate(['/']);
    }
  }

  private prepareEmbedDashboard(): void {
    const payload = {
      resource: { dashboard: this.METABASE_DASHBOARD_ID },
      params: {},
      exp: Math.round(Date.now() / 1000) + 10 * 60
    };

    const alg = 'HS256';

    if (this.METABASE_SECRET_KEY != null) {
      new jose.SignJWT(payload)
        .setProtectedHeader({ alg })
        .sign(this.METABASE_SECRET_KEY)
        .then(token => {
          this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            `${this.METABASE_SITE_URL}/embed/dashboard/${token}#bordered=false&titled=false`
          );
        });
    }
  }

  public getAllData(): void {
    this.isSpinning = true;
    this.systemSettingService
      .getAll()
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: async systemSettings => {
          this.isSpinning = false;
          this.systemSettings = systemSettings;
          this.fillConfigurationsVariables();
          this.showModalFromRoute();
          this.prepareEmbedDashboard();
        },
        error: error => {
          this.isSpinning = false;
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  fillConfigurationsVariables(): void {
    this.METABASE_SECRET_KEY = new TextEncoder().encode(
      this.systemSettings.find(setting => setting.key === CONSTANTS.METABASE_EMBEDDING_SECRET_KEY)
        ?.value
    );
    this.METABASE_SITE_URL = this.systemSettings.find(
      setting => setting.key === CONSTANTS.METABASE_SITE_URL
    )?.value;
    this.METABASE_DASHBOARD_ID = Number(
      this.systemSettings.find(setting => setting.key === CONSTANTS.METABASE_DASHBOARD_ID)?.value
    );

    if (this.METABASE_SITE_URL && !/^https?:\/\//i.test(this.METABASE_SITE_URL)) {
      this.message.showErrorByStatus(404);
      this.METABASE_SITE_URL = undefined;
      this.router.navigate(['/']);
    }
  }

  public ngOnInit(): void {
    this.getAllData();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
