import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ConsolidatedService } from '@core/services/consolidated/consolidated.service';
import { DownloadService } from '@core/services/download/download.service';
import { FinancialFileService } from '@core/services/financial-file/financial-file.service';
import { MessageService } from '@core/services/message/message.service';
import { userGroups } from '@env';
import { FinancialFileModel } from '@models/financial-file';
import { TranslateService } from '@ngx-translate/core';
import { UserInfoService } from '@pages/user-info.service';
import { ConsolidatedStatus } from '@shared/enums';
import { CONSTANTS } from '@shared/utils/constants';
import { getContainerFinancialFileTypes } from '@shared/utils/utils';
import { capitalize, startCase } from 'lodash';
import { Subject, takeUntil } from 'rxjs';
import { FilesComponentService } from '../files-component.service';

declare let require: any;
const FileSaver = require('file-saver');

@Component({
  selector: 'app-consolidated-tab',
  templateUrl: './consolidated-tab.component.html',
  styleUrls: ['./consolidated-tab.component.scss']
})
export class ConsolidatedTabComponent implements OnDestroy {
  @Input() filesList: FinancialFileModel[] = [];

  @Input() userGroup = '';

  @Input() lastDmiImportDate: Date | null = null;

  @Input() dateRef = new Date();

  @Input() fileGeneralConsolidated: FinancialFileModel = new FinancialFileModel();

  private readonly destroy$ = new Subject<void>();

  public ConsolidatedStatus = ConsolidatedStatus;

  public userGroups = userGroups;

  public CONSTANTS = CONSTANTS;

  public isSpinning = false;

  public isDownloading = false;

  constructor(
    public translate: TranslateService,
    public financialFileService: FinancialFileService,
    public consolidatedService: ConsolidatedService,
    public downloadService: DownloadService,
    public userInfoService: UserInfoService,
    public message: MessageService,
    public filesComponentService: FilesComponentService,
    private router: Router
  ) {}

  public showComparativeSquad(file: FinancialFileModel): void {
    this.router.navigate(['/files/comparative'], {
      queryParams: {
        financial_file_id: file.id,
        last_dmi_import_date: this.lastDmiImportDate,
        ref_date: this.dateRef.toISOString().substring(0, 10)
      }
    });
  }

  public generateConsolidateGeneral(event: any): void {
    this.filesComponentService.loading.next(true);
    event.target.disabled = true;
    this.fileGeneralConsolidated.isLoadingConsolidated = true;
    this.consolidatedService
      .generatedConsolidatedGeneral(this.fileGeneralConsolidated.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.fileGeneralConsolidated.isLoadingConsolidated = false;
          this.financialFileService.updateFilesSubject.next();
          this.filesComponentService.loading.next(false);
          this.message.showSuccess(this.translate.instant('Consolidated_successfully_generated'));
        },
        error: error => {
          this.fileGeneralConsolidated.isLoadingConsolidated = false;
          this.filesComponentService.loading.next(false);
          this.message.showErrorByStatus(error.status);
        }
      });
  }

  public downloadFile(file: FinancialFileModel): void {
    this.isDownloading = true;
    const downloadFileName = this.getDownloadFileName(file);
    this.downloadService
      .downloadFile(this.fileGeneralConsolidated.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: blob => {
          FileSaver.saveAs(blob, downloadFileName);
          this.isDownloading = false;
        },
        error: error => {
          this.isDownloading = false;
          this.getMessageErrorDownloadFile(Number(error.status), file.fileName);
        }
      });
  }

  public getMessageErrorDownloadFile(error: number, fileName: string): void {
    const msg = this.getMessage(error, fileName);

    if (msg != '') this.message.showError(msg);
  }

  private getMessage(error: number, fileName: string): string {
    if (error === 404) {
      if (fileName === CONSTANTS.FILE_PLANNED) return 'File_not_found_planned';

      if (fileName === CONSTANTS.FILE_CONSOLIDATED) return 'File_not_found_consolidated';

      if (fileName === CONSTANTS.FILE_EXECUTED) return 'File_not_found_executed';
    }
    return `api_status.${error}`;
  }

  private getDownloadFileName = (file: FinancialFileModel): string => {
    const fileType = capitalize(getContainerFinancialFileTypes(file.fileType));

    const month = String(file.referenceMonth).padStart(2, '0');

    const year = file.referenceYear;

    const squadName = startCase(file?.squad?.squadName.toLowerCase()).replace(/ /g, '_');

    let filename = `${fileType}_General_${month}_${year}.xlsx`;

    if (squadName) {
      filename = `${fileType}_${squadName}_${month}_${year}.xlsx`;
    }

    return filename;
  };

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
