<nz-modal
  nzWidth="654px"
  nzCentered
  nzKeyboard="false"
  nzMaskClosable="false"
  (nzOnCancel)="handleClose()"
  (nzAfterOpen)="afterOpen()"
  [(nzVisible)]="isVisible"
>
  <ng-container *nzModalTitle>
    <div class="cm-modal-title">
      <div class="modal-title my-3">{{ 'Edit' | translate }}</div>
    </div>
    <div></div>
  </ng-container>

  <ng-container *nzModalContent>
    <div class="cm-modal-body">
      <nz-spin nzSize="large" [nzSpinning]="isSpinning">
        <div>
          <form nz-form [formGroup]="validationForm">
            <nz-row nzGutter="6">
              <nz-col [nzXs]="{ span: 24 }">
                <div class="mb-2">
                  <nz-form-label nzRequired>{{ 'Network' | translate }}</nz-form-label>
                  <nz-form-control nzHasFeedback nzErrorTip="{{ 'Required_field' | translate }}">
                    <nz-select nzShowSearch formControlName="commitmentNetwork" required>
                      <nz-option *ngIf="isLoadingNetworks" nzDisabled nzCustomContent>
                        <span nz-icon nzType="loading" class="loading-icon"></span>
                        {{ 'Loading' | translate }}
                      </nz-option>
                      <ng-container *ngFor="let network of networks">
                        <nz-option
                          *ngIf="!isLoadingNetworks"
                          [nzValue]="network.id"
                          [nzLabel]="network.networkName"
                        >
                        </nz-option>
                      </ng-container>
                    </nz-select>
                  </nz-form-control>
                </div>
              </nz-col>

              <nz-col [nzXs]="{ span: 24 }">
                <div class="mb-2">
                  <nz-form-label nzRequired>{{ 'Commitment_Value' | translate }}</nz-form-label>
                  <nz-form-control nzHasFeedback nzErrorTip="{{ 'Required_field' | translate }}">
                    <nz-input-group [nzPrefix]="'R$' | translate">
                      <input
                        nz-input
                        required
                        currencyMask
                        type="text"
                        maxlength="20"
                        formControlName="commitmentValue"
                        [options]="{ prefix: '', thousands: '.', decimal: ',', align: 'left' }"
                      />
                    </nz-input-group>
                  </nz-form-control>
                </div>
              </nz-col>
            </nz-row>
          </form>
        </div>
      </nz-spin>
    </div>
  </ng-container>

  <ng-container *nzModalFooter>
    <div *ngIf="!isSpinning" class="d-flex justify-content-end">
      <app-button class="me-2" type="link" (click)="handleClose()">
        {{ 'Cancel' | translate }}
      </app-button>
      <app-button type="primary" [disabled]="!validationForm?.valid" (click)="handleSave()">
        {{ 'Save_changes' | translate }}
      </app-button>
    </div>
  </ng-container>
</nz-modal>
